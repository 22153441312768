﻿.login-background {
    background: $primary-teal-blue url('/Assets/images/login-background.png') 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-size:contain;

    @media(min-width:1200px) {
        @media(max-height:820px) {
            .logo {
                img {
                    height: 60px;
                }
            }
        }
    }

    .login-background-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .login-container {
            margin-top: 4rem;
            max-width: 967px;
            padding: 0 30px;

            .login-content {
                border-radius: 50px 50px 0px 50px;
                border-bottom: 10px solid #fdcb2a;
                border-right: 10px solid #fdcb2a;
                background-color: white;

                @media (max-width:991px) {
                    border-radius: 30px 30px 0px 30px;
                }

                .login-title {
                    font-size: 36px;
                    line-height: 40px;

                    @media (max-width: 991px) {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                ul.login-body li {
                    @include bodytext;
                    color: $black;
                    font-family: 'Outfit', sans-serif;

                    p {
                        margin-bottom: 0;
                    }
                }

                .login-field {
                    label {
                        color: $black;
                        line-height: 1.5;
                        @include bodytext;
                    }

                    .form-control {
                        border-radius: 0;
                        border-color: $border-gray;
                        border-width: 1px;
                        @include bodytext;
                    }
                }

                .validation-summary-errors {
                    font-size: 14px !important;

                    ul {
                        padding: 0;

                        li {
                            color: $red !important;
                        }
                    }
                }

                .field-validation-error {
                    font-size: 14px !important;
                    color: #dc3545 !important;
                }

                .login-link {
                    @include bodytext-sm;
                    color: $light-teal-blue;
                    text-decoration: underline;
                }
            }

            label.no-account {
                color: white;
                @include bodytext;

                a {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
}

.login-property-selector {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid $lighter-gray;

    .login-property-selector-icon {
        width: 12px;
        flex-basis: 12px;
    }

    .login-property-enroll-icon {
        width: 44px;
        flex-basis: 44px;
    }

    .login-property-selector-notification {
        width: 53px;
        flex-basis: 53px;

        img {
            width: 33px;
        }
    }

    .form-check {
        padding-left: 32px;
        margin: 0;

        .form-check-input {
            margin-left: -32px;
            margin-top: 0.06rem;

            &[type="radio"] {
                width: 21px;
                height: 21px;
                border-color: $light-teal-blue;

                &:checked {
                    background-color: $light-teal-blue;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3e%3cpath d='m11,0C4.92,0,0,4.92,0,11s4.92,11,11,11,11-4.92,11-11S17.08,0,11,0Zm0,18c-3.87,0-7-3.13-7-7s3.13-7,7-7,7,3.13,7,7-3.13,7-7,7Z' fill='%23fff'/%3e%3c/svg%3e");
                }
            }

            &[type="checkbox"] {
                width: 21px;
                height: 21px;
                border-radius: 0;
                border-color: $primary;

                & + .form-check-label {
                    font-size: 20px;
                    line-height: 25px;
                    color: $black;
                }
            }
        }

        label {
        }
    }

    &[disabled] {
        color: $dark-gray;

        .pending-account {
            font-style: italic;
            font-weight: normal;
            padding-left: 25px;
            font-size: 16px;
            text-align: right;
            color: $black;
        }
    }
}

.login-property-selector-actions {
    display: flex;
    margin-left: -14px;
    margin-right: -14px;

    & > div {
        padding: 0;
        padding-left: 14px;
        padding-right: 14px;
    }
}
