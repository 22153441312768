﻿.ui-datepicker {
    .ui-widget-header.ui-datepicker-header {
        border: none;
        background: none;
    }

    .ui-datepicker-next, .ui-datepicker-prev {
        background: none !important;
        border: none !important;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: none;
        }

        &.ui-state-hover {
            background: none !important;
            border: none !important;
        }
    }

    .ui-datepicker-next, .ui-datepicker-prev {
        &:hover {
            text-decoration: none;
            cursor: pointer;
        }

        &:before {
            font-family: 'de' !important;
            font-size: 12px;
            color: $dark-gray;
            position: relative;
            pointer-events: none;
        }
    }

    .ui-datepicker-next {
        text-decoration: none;

        &:before {
            content: '\e903'
        }
    }

    .ui-datepicker-prev {
        text-decoration: none;

        &:before {
            content: '\e903';
            transform: rotate(180deg);
        }
    }

    p, a {
        font-size: 16px;
        line-height: 1.2;
    }

    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
        background: none;
        text-align: center;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    td {
        &.ui-datepicker-unselectable {
            background-color: $medium-gray;

            > a {
                color: $dark-gray;
            }
        }

        &:not(.ui-datepicker-unselectable) {
            &:not(.ui-datepicker-current-day) {
                background-color: $light-teal-blue;
            }

            &.ui-datepicker-current-day {
                background-color: $yellow;

                > a {
                    background-color: transparent;
                }
            }

            > a {
                color: $white;
            }
        }

        > a {
            border: none !important;
        }
    }


    thead {
        border-bottom: 1px solid $light-gray;

        th {
            span {
                color: $medium-gray;
                font-weight: normal;
            }
        }
    }

    tr, td {
        a {
            font-size: 14px;
        }
    }
}

.datepicker-wrapper {
    position: relative;

    .calendar-icon-wrap {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 15px;
        justify-content: center;
        height: 100%;
        top: 0;
        bottom: 0;
    }
}
