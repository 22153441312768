﻿header {
    background: $white;
    margin-top: 0;
    margin-bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;

    @media (min-width: 992px) {
    }

    * {
        transition: all 0.3s ease;
    }

    .top-row {
        background: $light-gray;
        width: 100%;
        margin-top: 0;
        position: relative;

        @media (max-width: 991px) {
            display: none;
        }

        .phone-number {
            color: $black;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            display: flex;
            align-items: center;
            margin: 0 0 0 10px;

            span {
                margin-right: 3px;
            }
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style-type: none;
                margin: 0 20px 0 10px;
                display: inline-block;
                position: relative;
                padding-top: 0;

                &:after {
                    content: '';
                    position: absolute;
                    right: -20px;
                    top: 13px;
                    height: 5px;
                    width: 5px;
                    border-radius: 50%;
                    display: block;
                    background: $black;
                }

                a {
                    color: $black;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 2;
                    text-decoration: none;

                    @media (min-width: 991px) and (max-width:1199px) {
                        font-size: 16px;
                    }

                    &:hover {
                        color: $light-teal-blue;
                        text-decoration: none;
                    }
                }
            }

            &.social-links {
                margin: 0;

                li {
                    a {
                        font-size: 16px;
                        line-height: 42px;

                        span {
                            color: $white;
                        }

                        &:hover {
                            text-decoration: none;

                            span {
                                color: $light-teal-blue;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .logo {
        img {
            height: 72px;
            transition: none;
        }
    }

    &.sticky {
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        margin-top: 0;

        @media (min-width: 992px) {
            &:not(.checkout_header) {
                height: auto;
            }

            &.ab-on {
                height: auto;
            }
        }

        .top-row {
            display: none;
        }

        &.checkout_header {
            margin-top: 0;

            @media (min-width: 992px) {
                .extra-links {
                    display: flex !important;
                }

                .alt-menu {
                    display: none !important;
                }
            }

            @media (max-width: 991px) {
                .alt-menu {
                    display: none !important;
                }
            }

            .steps-outer-wrapper {
                bottom: 15px;
                transition: all 0.3s ease;
            }
        }

        .extra-links {
            display: none !important;
        }

        .alt-menu {
            display: none !important;

            @media (max-width: 991px) {
                display: block !important;
            }
        }

        .logo {
            img {
                height: 40px;
            }
        }
    }

    .menu-wrapper {
        display: flex;
        align-items: center;
        float: right;
    }

    ul.main-nav {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            float: left;
            margin-right: 15px;

            a {
                text-decoration: none;
                color:$black;

                &.active {
                    color: $light-teal-blue;
                }
            }
        }
    }

    .phone-mobile, .login-mobile {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $light-teal-blue;
        border-radius: 50%;
        float: right;
        margin-right: 10px;
        text-decoration: none;

        span {
            color: $white;
            font-size: 16px;
        }

        &:hover {
            text-decoration: none;
            background: $primary-teal-blue;
        }
    }

    .mobile-menu-btn {
        float: right;
        padding: 0;
        margin-right: 0px;
        position: relative;

        span {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $light-teal-blue;
            font-size: 24px;
        }

        &.menu-opened {
            span {
                color: $medium-gray;
            }

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 12px 12px;
                border-color: transparent transparent $light-teal-blue transparent;
                position: absolute;
                bottom: -13px;
                right: 0;
            }
        }
    }

    .navbar {
        position: unset;
        padding: 0;

        a {
            font-weight: bold;
            font-size: 20px;
            color: $primary-teal-blue;
            border-bottom: 2px solid $white;

            @media (min-width: 991px) and (max-width:1199px) {
                font-size: 20px;
            }

            &:hover {
                text-decoration: none;
                border-bottom: 2px solid $light-teal-blue;
                color: $light-teal-blue;
            }
        }

        .mobile-nav {
            display: none;

            &.open {
                display: block;
            }
        }

        &.alt-menu {
            .navbar-nav {
                a.nav-link {
                    font-size: 20px;
                    padding: 10px 20px;
                    border-left: 3px solid $white;
                    border-bottom: 0;
                    display: block;
                    float: left;

                    &.active, &:hover {
                        border-left: 3px solid $light-teal-blue;
                    }
                }
            }

            .mobile-nav {
                display: none;

                &.open {
                    background: $white;
                    display: block;
                    position: absolute;
                    z-index: 10;
                    right: 10px;
                    min-width: 300px;
                    text-align: left;
                    box-shadow: 0 0 5px rgba(0,0,0,0.16);
                    top: 110%;
                }

                .navbar-nav {
                    flex-direction: column;

                    li {
                        display: inline-block;
                        width: 100%;

                        ul {
                            display: none;
                        }

                        &.open {
                            .caret {
                                &:before {
                                    transform: rotate(180deg);
                                }
                            }

                            ul {
                                display: block;
                                margin: 0;
                                padding: 0;

                                li {
                                    background: $lighter-gray;

                                    a.nav-link {
                                        font-size: 20px;
                                        padding: 5px 20px;
                                        border-left: 3px solid $lighter-gray;
                                        border-bottom: none;
                                        display: block;
                                        float: left;

                                        &.active, &:hover {
                                            border-left: 3px solid $light-teal-blue;
                                        }
                                    }
                                }
                            }
                        }

                        .caret {
                            display: block;
                            float: right;
                            height: 35px;
                            width: 25px;
                            padding: 20px;
                            position: relative;
                            z-index: 10;
                            cursor: pointer;

                            &:before {
                                content: '';
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 10px 6px 0 6px;
                                border-color: $primary-teal-blue transparent transparent transparent;
                                position: absolute;
                                z-index: 0;
                                right: 10px;
                                top: 20px;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .extra-links {
        a {
            font-weight: bold;
            font-size: 25px;
            color: $primary-teal-blue;
            text-decoration: none;

            @media (min-width: 991px) and (max-width:1199px) {
                font-size: 20px;
            }

            span {
                display: block;
                float: left;
                margin-top: 10px;
                font-size: 22px;
                margin-right: 4px;
            }

            &:hover {
                text-decoration: none;
                color: $light-teal-blue;
            }

            &.btn {
                color: $white;
            }
        }
    }

    .steps-outer-wrapper {
        position: absolute;
        top: auto;
        bottom: 20px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .announcement-bar {
        background: #FDCB2A;
        padding: 15px 0;
        color: $primary-teal-blue;
        text-align: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: 5;
        @include bodytext;

        .row {
            @media (max-width:767px) {
                flex-direction: column;
            }
        }

        p {
            @include bodytext-sm;
            color: $primary-teal-blue;
            font-weight: 600;
            margin-bottom: 0;

            @media (min-width: 992px) {
                padding-right: 20px;
            }

            span {
                display: inline-block;
                width: 100%;
                text-transform: uppercase;
            }

            a {
                color: $light-teal-blue;
                text-decoration: underline;
                font-size: 22px;

                @media (max-width: 991px) {
                    font-size: 16px;
                }

                &:hover {
                    color: $white;
                }
            }
        }

        img {
            height: 52px;
            width: auto !important;
        }

        .container {
            position: relative;

            .de-close {
                position: absolute;
                top: 10px;
                right: 20px;
                font-size: 14px;
                color: $primary-teal-blue;

                &:hover {
                    color: $primary-teal-blue;
                }
            }
        }
    }

    .impersonate-bar {
        width: 100%;
        background-color: $yellow;

        p {
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
}
.steps-wrapper {
    @media (max-width: 991px) {
        justify-content: stretch !important;
        align-content: stretch !important;
        width: 100%;
        padding: 0 0 10px;
    }

    .step {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        position: relative;
        opacity: 1;

        @media (max-width: 991px) {
            flex-grow: 1;
        }

        @media (max-width:767px) {
            padding: 0 5px;
        }

        .step-circle {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            color: $white;
            background: $light-teal-blue;
            margin: 15px 0 13px;

            @media (max-width: 991px) {
                height: 18px;
                width: 18px;
            }

            .number {
                font-size: 18px;
                color: $white;

                @media (max-width: 991px) {
                    font-size: 9px;
                }
            }

            span.de-checkmark {
                font-size: 10px;
                color: $white;

                @media (max-width: 991px) {
                    font-size: 8px;
                }
            }

            span.de-checkmark {
                display: none;
            }
        }

        .step-name {
            font-size: 15px;
            text-transform: uppercase;
            color: $primary-teal-blue;
            border-bottom: 3px solid $light-teal-blue;
            padding: 0 15px;

            @media (max-width: 991px) {
                font-size: 8px;
            }

            @media (max-width:767px) {
                text-align: center;
                padding: 0 7px;
            }
        }

        img {
            display: none;

            @media (max-width: 991px) {
                height: 58px;
            }
        }

        &.completed {
            .step-circle {
                background: $light-teal-blue;

                span.de-checkmark {
                    display: block;
                }

                .number {
                    display: none;
                }
            }
        }

        &.current-step {
            opacity: 1;

            .step-circle {
                display: none;
            }

            .step-name {
                border-bottom: none;
                color: $light-teal-blue;
            }

            img {
                display: block;
                height: 28px;
            }
        }

        &.step-last {
            @media (max-width: 991px) {
                padding-right: 0;
            }

            &:after {
                display: none;
            }
        }

        &.step-first {
            @media (max-width: 991px) {
                padding-left: 0;
            }
        }
    }
}
@media (max-width: 991px) {
    header {
        &.sticky {
            margin: 0;

            .logo {
                img {
                    height: 35px;
                }
            }
        }

        .logo {
            img {
                height: 35px;
            }
        }

        .navbar {
            &.alt-menu {
                .mobile-nav {
                    &.open {
                        top: 100%;
                    }
                }
            }
        }
    }
}