div.TDSP-div {
    background-color: #f5f5f5;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
.divTable.TDSP-div .divTableCell,
.divTable.TDSP-div .divTableHead {
    border: 1px solid #ffffff;
    padding: 5px 5px;
    color: #102247;
}
.divTable.TDSP-div .divTableBody .divTableCell {
    font-size: 15px;
    color: #102247;
}
.divTable.TDSP-div .divTableHeading {
    background: $primary-teal-blue;
}
.divTable.TDSP-div .divTableHeading .divTableHead {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
}
.TDSP-div .tableFootStyle {
    font-weight: bold;
}
/* DivTable.com */
div.TDSP-div {
    display: grid;
    grid-template-columns: 40% 60%;
}
div.TDSP-div .divTableBody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.divTableRow div {
    height: 50px;
}
.divTableRow div:first-child {
    height: 90px;
}

@media only screen and (min-width: 768px) {
    .divTable.TDSP-div .divTableHeading .divTableHead {
        font-size: 15px;
    }
    div.TDSP-div {
        display: table;
    }
    div.TDSP-div .divTableBody {
        display: table-row-group;
    }
    .divTableRow div {
        height: auto;
    }
    .divTableRow div:first-child {
        height: auto;
    }
    .divTable {
        display: table;
    }
    .divTableRow {
        display: table-row;
    }
    .divTableHeading {
        display: table-header-group;
    }
    .divTableCell,
    .divTableHead {
        display: table-cell;
    }
    .divTableHeading {
        display: table-header-group;
    }
    .divTableFoot {
        display: table-footer-group;
    }
    .divTableBody {
        display: table-row-group;
    }
}
